/** @jsxRuntime classic */
/** @jsx h */
import {
  autocomplete,
  AutocompleteComponents,
  getAlgoliaResults,
  AutocompleteInsightsApi,
} from '@algolia/autocomplete-js';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import algoliasearch from 'algoliasearch/lite';
import { h, Fragment } from 'preact';

import '@algolia/autocomplete-theme-classic';

import { createCategoriesPlugin } from './categoriesPlugin';
import { shortcutsPlugin } from './shortcutsPlugin';
import { ProductRecord, ProductHit } from './types';

const appId = 'AZ4WM686LE';
const apiKey = '0d675779ec7bbb787e4e0d4a5376a476';
const searchClient = algoliasearch(appId, apiKey);

const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
  key: 'games',
  limit: 3,
});
const querySuggestionsPlugin = createQuerySuggestionsPlugin({
  searchClient,
  indexName: 'game_suggestions',
  getSearchParams({ state }) {
    return recentSearchesPlugin.data.getAlgoliaSearchParams({
      hitsPerPage: state.query ? 5 : 10,
    });
  },
  categoryAttribute: [
    'games',
    'facets',
    'exact_matches',
    'producer_name',
  ],
  categoriesPerItem: 2,
});
const categoriesPlugin = createCategoriesPlugin({ searchClient });

autocomplete<ProductHit>({
  container: '#autocomplete',
  placeholder: 'Search',
  debug: true,
  openOnFocus: true,
  insights: true,
  plugins: [
    shortcutsPlugin,
    recentSearchesPlugin,
    querySuggestionsPlugin,
    categoriesPlugin,
  ],
  getSources({ query, state }) {
    if (!query) {
      return [];
    }

    return [
      {
        sourceId: 'games',
        getItems() {
          return getAlgoliaResults<ProductRecord>({
            searchClient,
            queries: [
              {
                indexName: 'games',
                query,
                params: {
                  attributesToSnippet: ['name:10', 'description:35'],
                  snippetEllipsisText: '…',
                },
              },
            ],

          });
        },
        templates: {
          header() {
            return (
              <Fragment>
                <span className="aa-SourceHeaderTitle">Games</span>
                <div className="aa-SourceHeaderLine" />
              </Fragment>
            );
          },
          item({ item, components }) {
            return (
              <ProductItem
                hit={item}
                components={components}
                insights={state.context.algoliaInsightsPlugin.insights}
              />
            );
          },
          noResults() {
            return 'No matching games. Try broadening your search.';
          },
        },
      },
    ];
  },
});

type ProductItemProps = {
  hit: ProductHit;
  insights: AutocompleteInsightsApi;
  components: AutocompleteComponents;
};

function ProductItem({ hit, insights, components }: ProductItemProps) {
  return (
    <a href={hit.url} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <div className="aa-ItemIcon aa-ItemIcon--picture aa-ItemIcon--alignTop">
          <img src={hit.image} alt={hit.title} width="40" height="40" />
        </div>

        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            <components.Snippet hit={hit} attribute="title" />
          </div>
          <div className="aa-ItemContentDescription">
            By <strong>{hit.producer_name}</strong> in{' '}

          </div>

          <div
            className="aa-ItemContentDescription"
            style={{
              display: 'grid',
              gridAutoFlow: 'column',
              justifyContent: 'start',
              alignItems: 'center',
              gap: 8,
            }}
          >
            {hit.rating > 0 && (
              <div className="aa-ItemContentDescription">
                <div
                  style={{
                    color: 'rgba(var(--aa-muted-color-rgb), 0.5)',
                  }}
                >
                  {Array.from({ length: 5 }, (_value, index) => {
                    const isFilled = hit.rating >= index + 1;

                    return (
                      <svg
                        key={index}
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill={isFilled ? 'currentColor' : 'none'}
                        stroke="currentColor"
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                      </svg>
                    );
                  })}
                </div>
              </div>
            )}
            <div
              style={{
                display: 'grid',
                gridAutoFlow: 'column',
                justifyContent: 'start',
                alignItems: 'center',
                gap: 4,
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{
                  position: 'relative',
                  top: '1px',
                }}
              >
                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
              </svg>

            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
            }}
          >
            <div
              style={{
                display: 'grid',
                gridAutoFlow: 'column',
                justifyContent: 'start',
                alignItems: 'center',
                gap: 4,
              }}
            >
              <div>
                <span
                  style={{
                    color: '#000',
                    fontSize: '0.9em',
                    fontWeight: 'bold',
                  }}
                >

                </span>{' '}
                {hit.sale && (
                  <span
                    style={{
                      color: 'rgb(var(--aa-muted-color-rgb))',
                      fontSize: '0.9em',
                      textDecoration: 'line-through',
                    }}
                  >
                    ${hit.price.toLocaleString()}
                  </span>
                )}
              </div>
              {hit.sale && (
                <span
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '0.64em',
                    background: '#539753',
                    color: '#fff',
                    fontWeight: 600,
                    borderRadius: 9999,
                    padding: '2px 6px',
                    display: 'inline-block',
                    lineHeight: '1.25em',
                  }}
                >
                  On sale
                </span>
              )}
            </div>
          </div>
        </div>
      </div>


    </a>
  );
}
